import Swiper from "swiper"
export default function instagramApi() {


    jQuery.fn.FCInstagram.accessData = {
        accessToken: "IGACKVSTtm9GNBZAE1oTGpTYkxMN3R5cWZA0YzB5aWJQUXBzLTlJQ1dGWmpqaUlGd1ppcENJbnpuN2cyRzFMbWlLSlNSblAyMXpGTmJBWkhydGpMVVdjVDF5dlNsb1VMZAHNRZAk5hWkMzSGxGbXZALWVhqRUZAjRlNSbzJCTHRBRDBiVQZDZD",
        // Token
    };

    $('#instafeed').FCInstagram({
        max: 10, // A number between 1 and 25 of photos to show. Default: 9
        autoplay: false, // Set autoplay video: true/false. Default: false
        complete: function () { // A callback function to execute after the display of the photos.
            console.log('completed');

            const instaItems = document.querySelectorAll('#instafeed a')
            const instaWrapper = document.querySelector('#instafeed')

            const htmlSlide = [...instaItems].map(item => {
                return `
                <div  class="swiper-slide">
                        <span style="visibility:hidden">-</span>
                        <div>
                            ${item.outerHTML}    
                            <div class="content">
                            <svg>
                                <use xlink:href="#icon_instagram_white"></use>
                            </svg>
                            <span class="text-caps-2">Ver no instagram</span>
                            </div>
                        </div>
                </div>
                `
            }).join('')

            instaWrapper.innerHTML = `          
            <div class="swiper swiper-acompanhe-nos" style="display: grid; width:100%;">
                <div class="swiper-wrapper">
                ${htmlSlide}
                </div>
            </div>
            `

            const swiperAcompanhe = new Swiper(".swiper-acompanhe-nos", {
                lazy: true,
                slidesPerView: 'auto',
                spaceBetween: 8,
                centeredSlides: true,
                centeredSlidesBounds: true,

                // autoplay:{
                //     delay: 3000
                // }

            });
        }
    });


}